/**
 * modules
 */

// import preloader from '../../../templates/_atoms/preloader/_script';
import accordion from '../../../templates/_organisms/accordion/_script';
import gridOverlay from '../../../templates/_molecules/gridOverlay/_script';
import navMain from '../../../templates/_molecules/navMain/_script';

// Modules Array
const modulesArray = [accordion, gridOverlay, navMain];

const modules = {
  // Modules
  Modules() {
    if (modulesArray.length > 0 && modulesArray !== undefined) {
      modulesArray.forEach((module) => {
        module.init();
      });
    }
  },

  init() {
    this.Modules();
  },
};

export default modules;
